<template>
  <b-modal v-model="isModalActive" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-label="Example Modal" aria-modal>
    <template #default>
      <div class="modal-card">
        <header class="modal-card-head has-background-white">
          <p class="modal-card-title has-text-primary has-text-weight-semibold">
            Adicionar Range de Períodos
          </p>
          <button type="button" class="delete" @click="$emit('close')" />
        </header>
        <section class="modal-card-body">
          <form @submit="handleSubmit">
            <div class="columns is-multiline">
              <b-field label="Data Inicial" horizontal class="column is-full">
                <b-input v-model="form.dataI" name="data-inicial" type="date" :min="minDateToSelect" :max="form.dataF" required expanded />
              </b-field>
              <b-field label="Data Final" horizontal class="column is-full">
                <b-input v-model="form.dataF" name="data-final" type="date" :min="form.dataI || minDateToSelect" required expanded />
              </b-field>
              <b-field label="Valor" horizontal class="column is-full">
                <b-input v-model="form.valor" name="valor" type="number" :min="0" required expanded />
              </b-field>
              <div class="column is-full">
                <b-button type="is-primary" expanded tag="button" native-type="submit" label="Salvar"> </b-button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { formatDateToInput } from '@/helpers/helpers';

export default {
  name: 'ModalAdicionarRangePeriodo',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      minDateToSelect: formatDateToInput(new Date()),
      isModalActive: this.active,
      form: {},
    };
  },
  watch: {
    active(newValue) {
      this.isModalActive = newValue;
    },
    isModalActive(newValue) {
      if (!newValue) {
        this.closeModal();
      }
    },
  },
  created() {
    this.form = {};
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    handleSubmit(event) {
      event.preventDefault();

      this.$emit('save', this.form);
    },
  },
};
</script>
