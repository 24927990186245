<template>
  <section class="section">
    <form class="container-admin" @submit="handleSubmit">
      <b-loading :active.sync="isLoading" />
      <div class="columns is-multiline">
        <div class="column is-full">
          <h3 class="title is-3">
            <router-link to="/admin/gerenciar-tarifas" class="has-text-grey">
              Gerenciamento de Tarifas
            </router-link>
            >
            <span class="has-text-primary">{{ isNew ? 'Cadastrar' : 'Editar' }}</span>
          </h3>
        </div>
        <b-field label="Tarifa Tipo" class="column">
          <b-select v-model="form.tarifaTipoId" name="estoque" placeholder="Selecione" expanded required>
            <option v-for="tipo in tipos" :key="tipo.id" :value="tipo.id">
              {{ tipo.nome }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Nome" class="column">
          <b-input v-model="form.nome" type="text" placeholder="Digite" required></b-input>
        </b-field>
        <b-field label="Min. Diária" class="column">
          <b-input v-model="form.minDiaria" name="min-diaria" type="number" placeholder="Digite" required />
        </b-field>
        <b-field label="Ordem de Exibição" class="column">
          <b-input v-model="form.ordemExibicao" name="limite-de-pessoas" type="number" placeholder="Digite" required />
        </b-field>
        <b-field label="Detalhe" class="column is-full">
          <b-input v-model="form.detalhe" name="detalhe" type="textarea" placeholder="Digite" required />
        </b-field>
      </div>

      <hr />

      <div class="columns">
        <div class="column">
          <h2 class="title is-2">Períodos</h2>
        </div>
        <div class="column is-narrow">
          <b-button type="is-secondary" icon-left="plus" class="mr-3" @click="addRangePeriodo">
            Adicionar Range
          </b-button>
          <b-button type="is-secondary" icon-left="plus" class="mr-3" @click="addPeriod">
            Adicionar Período
          </b-button>
          <b-button type="is-black" icon-left="sort" :disabled="periodos.length < 2" @click="sortPeriods">
            Reordenar
          </b-button>
        </div>
      </div>

      <b-message v-if="periodos.length === 0">
        Nenhum período cadastrado para esta tarifa.
      </b-message>
      <div v-else class="columns" v-for="(periodo, index) in periodos" :key="index">
        <b-field label="Data" class="column mb-0">
          <b-input v-model="periodo.data" name="data-inicial" type="date" expanded :min="minDate" required />
        </b-field>
        <b-field label="Valor" class="column mb-0">
          <b-input v-model="periodo.valor" type="number" placeholder="Digite" step="0.01" min="0" required />
        </b-field>
        <b-field label="Estoque" class="column mb-0">
          <b-input v-model="periodo.estoque" type="number" placeholder="Digite" step="1" min="0" required />
        </b-field>
        <div class="column is-narrow" style="display: flex; align-items: flex-end">
          <b-button type="is-black" icon-left="delete" @click="deletePeriod(index)" />
        </div>
      </div>

      <hr />

      <b-button type="is-primary" tag="button" native-type="submit" label="Salvar" expanded />
    </form>
    <ModalAdicionarRangePeriodo :active="isModalAddRangePeriodoActive" @close="closeModalRangePeriodo" @save="handleSaveRangePeriodo" />
  </section>
</template>

<script>
import dayjs from 'dayjs';
import ModalAdicionarRangePeriodo from '@/components/Cadastros/Tarifa/ModalAdicionarRangePeriodo.vue';
import tarifaTipoService from '@/services/tarifaTipoService';
import hoteisService from '@/services/hoteisService';
import tarifaService from '@/services/tarifaService';
import { formatDateToInput } from '@/helpers/helpers';

export default {
  name: 'CadastrarTarifas',
  components: {
    ModalAdicionarRangePeriodo,
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      isModalAddRangePeriodoActive: false,
      form: {},
      tipos: [],
      periodos: [],
    };
  },
  computed: {
    isNew() {
      return !this.id;
    },
    minDate() {
      return this.isNew ? formatDateToInput(new Date()) : null;
    },
  },
  async created() {
    this.isLoading = true;

    await this.getTipos();

    if (!this.isNew) {
      const { data } = await tarifaService.getById(this.id);
      const { periodos, ...formData } = data;
      const periodosFormatados = periodos.map(p => {
        let date = new Date(p.data);
        date = new Date(date.setDate(date.getDate() + 1));
        p.data = formatDateToInput(date);
        return p;
      });

      this.form = formData;
      this.periodos = periodosFormatados || [];
      this.sortPeriods();
    }

    this.isLoading = false;
  },
  methods: {
    getTipos() {
      tarifaTipoService.get().then(({ data }) => {
        this.tipos = data;
      });
    },
    async handleSubmit(event) {
      event.preventDefault();
      this.isLoading = true;

      const data = { ...this.form };
      data.periodos = [...this.periodos];

      if (this.isNew) {
        const tarifaTipo = this.tipos.find(t => t.id === data.tarifaTipoId);
        const { data: hotel } = await hoteisService.getById(tarifaTipo.id);
        data.clienteId = hotel.clienteId;

        tarifaService
          .post(data)
          .then(() => {
            this.$router.push('/admin/gerenciar-tarifas');
            this.$buefy.snackbar.open('Tarifa cadastrada com sucesso');
          })
          .catch(() => {
            this.$buefy.snackbar.open({
              message: 'Erro ao cadastrar tarifa',
              type: 'is-danger',
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        tarifaService
          .put(data)
          .then(() => {
            this.$router.push('/admin/gerenciar-tarifas');
            this.$buefy.snackbar.open('Tarifa editada com sucesso');
          })
          .catch(() => {
            this.$buefy.snackbar.open({
              message: 'Erro ao editar tarifa',
              type: 'is-danger',
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    addPeriod() {
      const ultimoPeriodo = [...this.periodos].pop();

      const lastDate = dayjs(ultimoPeriodo.data)
        .add(1, 'day')
        .format('YYYY-MM-DD');

      this.periodos.push({
        data: lastDate,
        estoque: 2,
      });
    },
    addRangePeriodo() {
      this.isModalAddRangePeriodoActive = true;
    },
    deletePeriod(index) {
      this.periodos.splice(index, 1);
    },
    sortPeriods() {
      this.periodos.sort((a, b) => {
        const date1 = a.data;
        const date2 = b.data;
        if (date1 === date2) return 0;
        if (date1 > date2) return 1;
        if (date1 < date2) return -1;
      });
    },
    closeModalRangePeriodo() {
      this.isModalAddRangePeriodoActive = false;
    },
    handleSaveRangePeriodo({ dataI, dataF, valor }) {
      const start = new Date(dataI);
      const end = new Date(dataF);
      let loop = new Date(start.setDate(start.getDate() + 1));
      const loopEnd = new Date(end.setDate(end.getDate() + 1));

      while (loop <= loopEnd) {
        const dataPeriodo = formatDateToInput(loop);
        if (!this.periodos.some(p => p.data === dataPeriodo)) {
          this.periodos.push({
            data: dataPeriodo,
            valor,
            estoque: 2,
          });
        }

        loop = new Date(loop.setDate(loop.getDate() + 1));
      }

      this.isModalAddRangePeriodoActive = false;
    },
  },
};
</script>

<style>
.upload-label {
  width: 100%;
  height: 100%;
  background-color: rgb(224, 224, 224);
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed gray;
  border-radius: 8px;
}

.upload-label div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-label img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.container-image {
  width: 100px;
  height: 100px;
  display: inline-flex;
  margin-right: 8px;
  position: relative;
  margin-bottom: 8px;
}

.wrapper-gallery {
  display: flex;
  flex-wrap: wrap;
}

.upload-image {
  width: 100px;
  height: 100px;
  display: inline-flex;
  margin-right: 8px;
  background-color: rgb(224, 224, 224);
  border: 1px dashed gray;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.button-remove-attachment {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: rgb(218, 218, 218);
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding: 2px;
  cursor: pointer;
}

.thumb-imagens {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}
</style>
